(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.directive:ngFileModel
   * @restrict A
   * @element
   *
   * @description
   *
   */
  angular
    .module('components')
    .directive('ngFileModel', ngFileModel);

  function ngFileModel($parse, $mdToast) {
    return {
      restrict: 'A',
      link: (scope, element, attrs) => {
        var model = $parse(attrs.ngFileModel),
            modelSetter = model.assign,
            fileModel = {
              element: element[0],
              click: () => {
                element[0].click();
              }
            };

        modelSetter(scope, fileModel);

        element.bind('change', () => {
          let size = element[0].files[0].size / 1024 / 1024;
          if (size >= 2) {
            $mdToast.show(
              $mdToast.simple()
                .textContent('The file size exceeds the limit of 2mb. Please reduce the file size and try again.')
                .position('bottom right')
                .hideDelay(3000)
            );
          } else {
            scope.$apply(function () {
              fileModel = {
                element: element[0],
                file: element[0].files[0],
                files: element[0].files,
                click: () => {
                  element[0].click();
                }
              };
              modelSetter(scope, fileModel);
            });
          }
        });
      }
    };
  }
}());
